import './App.css';
import desktopBackground from "../../img/hp_desktop_background.jpg";
import Post from "../Post/Post";


function App() {
  return (
    <div className="App" style={{ backgroundImage:`url(${desktopBackground})` }}>
      <Post/>
    </div>
  );
}

export default App;
