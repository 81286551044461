import "./Plank.css";
import wood from "../../img/plank-wood-small.png";


export default function Plank({title, link}) {
    return (
        <div className="plank-container">
            <a href={link}>
                <div className="plank-frame" style={{ background:`url(${wood}), #B7845C`, transform: `rotate(${((Math.random() * 5) - 1).toString()}deg)` }}>
                    <p>{title}</p>
                </div>
            </a>
        </div>
    );
}